import React, {useEffect} from 'react'
import { useRouter } from 'next/router'
import { Loader } from '@/components/shared/loader'
import { useUser } from 'utils/useUser';

export default function Page() {
  const Router = useRouter();
  const { isLoading, user } = useUser();
  useEffect(() => {
    Router.push('/app/dashboard')
  });
  if (isLoading) {
    return <Loader />
  } else if(user){
    Router.push('/app/dashboard')
  } else {
    Router.push('/app/login')
  }

}
